@import './utils/mixins';
@import './utils/variables';

.app {
  background-color: $backgroundColor;

  &__content {
    padding-bottom: 100px;
  }
}
