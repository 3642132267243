body,
p,
h1,
h2,
section,
ul,
a {
  margin: 0;
  padding: 0;
}
