@font-face {
  font-family: 'Nunito';
  src: url('../fonts/Nunito/Nunito-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Nunito';
  src: url('../fonts/Nunito/Nunito-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

html {
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.87);
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}
h1,
h2,
p,
a,
button,
input,
label {
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
}

h1,
h2 {
  font-size: 40px;
  line-height: 40px;
}
