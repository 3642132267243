@import '../../utils/variables';
@import '../../utils/mixins';

.signUpForm {
  margin: 0 auto;
  max-width: $maxScreenWidth;
  padding: 0 16px;

  &__title {
    margin-bottom: 50px;
    text-align: center;
  }

  &__form {
    margin: 0 auto;
    width: 100%;
    max-width: 380px;

    display: flex;
    flex-direction: column;

    .textInput {
      margin-bottom: 36px;

      &:nth-child(3) {
        margin-bottom: 25px;
      }
    }
  }

  &__radioGroup {
    padding: 0;
    border: none;
  }

  &__radioTitle {
    margin-bottom: 11px;
  }

  &__radioContainer {
    margin-bottom: 7px;

    &:last-child {
      margin-bottom: 47px;
    }
  }

  &__radioData {
    opacity: 0;

    &:checked {
      + .signUpForm__radioLable::before {
        border-color: #00BDD3;
        background-color: #00BDD3;
        box-shadow: inset 0 0 0 5px $backgroundColor;
      }
    }
  }

  &__radioLable {
    position: relative;
    left: 10px;

    &::before {
      width: 20px;
      height: 20px;
      content: '';

      display: inline-block;
      position: absolute;
      top: 1px;
      left: -32px;

      border: 1px solid #D0CFCF;
      border-radius: 50%;
    }

  }

  &__fileInputLabel {
    position: relative;
    margin-bottom: 50px;

    .fileButton {
      position: absolute;
      box-sizing: border-box;
      left: 0;
      top: 1px;
      height: 54px;
      width: 83px;

      text-align: center;
      line-height: 54px;

      border: 1px solid rgba(0, 0, 0, 0.87);
      border-radius: 4px 0px 0px 4px;
      background-color: $backgroundColor;
    }

    .fileName {
      padding: 14px 0 14px 99px;
      position: absolute;
      box-sizing: border-box;
      left: 0;
      top: 1px;
      height: 54px;
      width: 100%;

      border: 1px solid #D0CFCF;
      border-radius: 4px;
      color: #7E7E7E;

      &__filled {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }

  &__fileInput {
    opacity: 0;
    height: 54px;
    width: 100%;
  }

  &__errorText {
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    color: #CB3D40;
  }

  &__helperText {
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    color: #7E7E7E;
  }

  &__button {
    margin: 0 auto;
  }

  .success {
    &__title {
      margin-bottom: 50px;
      text-align: center;
    }

    &__image {
      margin: 0 auto;
      width: 328px;
      height: 290px;

      background-image: url('../../images/success-image.svg');
    }
  }
}
