@mixin onTablet() {
  @media(min-width: 768px) {
    @content;
  }
}

@mixin onDesktop() {
  @media(min-width: 1024px) {
    @content;
  }
}

@mixin onDesktopXL() {
  @media(min-width: 1170px) {
    @content;
  }
}
