@import '../../utils/variables';
@import '../../utils/mixins';

.userList {
  margin: 0 auto 140px;
  padding: 0 16px;
  max-width: $maxScreenWidth;
  text-align: center;

  @include onTablet {
    padding: 0 32px;
  }

  @include onDesktop {
    padding: 0 60px;
  }

  @include onDesktopXL {
    padding: 0;
  }

  &__title {
    margin-bottom: 50px;
    font-size: 40px;
    line-height: 40px;
  }

  &__content {
    margin-bottom: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;

    @include onTablet {
      gap: 16px;
    }

    @include onDesktop {
      gap: 29px;
    }
  }

  &__wraper {
    min-width: 282px;
    width: 100%;
    max-width: 370px;
    flex-grow: 1;

    @include onTablet {
      width: 45%;
    }

    @include onDesktop{
      width: 30%;
    }
  }
}
