.userCard {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  min-width: 282px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: stretch; // Flex-items will be stretch on 100% of parent element
  text-align: center;
  
  background-color: #FFFFFF;
  border-radius: 10px;
  white-space: nowrap; // Text won't be wrap. It stay in one line.

  &__photo {
    width: 70px;
    height: 70px;
    align-self: center;

    border-radius: 50%;
  }

  &__toolTip {
    position: relative;

    &::after {
      padding: 3px 16px;
      content: attr(data-title);
      display: block;
      width: max-content;

      position: absolute;
      top: 130%;
      left:auto;
      z-index: 1;
      
      text-align: center;
      color: #FFFFFF;
      background: rgba(0, 0, 0, 0.87);
      border-radius: 4px;
    }

    &--right {
      &::after {
        right: 0;
      }
    }
  }

  .textLink {
    display: block;
    text-decoration: none;
    color: inherit;
  }

  &__text {
    height: 26px;

    overflow: hidden; // All text wich beyond element will be hidden
    text-overflow: ellipsis; // All hidden overflow text will be replaced by '...'
                              // it doesn't work without overflow: hidden and white-space: nowrap
  }
}
