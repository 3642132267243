@import '../../utils/variables';
@import '../../utils/mixins';

.header {
  padding: 13px 16px 13px;

  background-color: #FFFFFF;

  @include onTablet {
    padding: 13px 32px 13px;
  }

  @include onDesktop {
    padding: 13px 60px 13px;
  }

  @include onDesktopXL {
    padding: 13px 0 13px;
  }

  &__content {
    margin: 0 auto;
    max-width: $maxScreenWidth;
    
    display: flex;
    justify-content: space-between;
  }

  &__image {
    width: 104px;
    height: 26px;

    background-image: url('../../images/Logo.svg');
    background-repeat: no-repeat;
  }

  &__button {
    margin-right: 10px;
  }
}

.button {
  display: inline-block;
  width: 100px;
  height: 34px;

  text-decoration: none;
  text-align: center;
  line-height: 34px;

  background-color: $primaryColor;
  border-radius: 80px;
  border: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);

  &:hover {
    background-color: $primaryColorHover;
  }

  &--big {
    width: 120px;
  }

  &--disabled {
    background-color: #B4B4B4;
    color: #FFFFFF;
    cursor: not-allowed;

    &:hover {
      background-color: #B4B4B4;
    }
  }
}
